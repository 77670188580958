

.vjs-big-play-button {
  .vjs-icon-placeholder {
  &:before {
    font-size: 40px;
    margin-top: -5px;
    color: #FFF;
  }
}
}

.vjs-remaining-time-display, .vjs-playback-rate-value {
  color: #FFF;
}
.vjs-play-progress, .vjs-volume-level {
  &:before {
    color: #FFF;
  } 
}
.vjs-icon-placeholder {
    &:before {
    color: #FFF;
  }
}

.vjs-menu li {
  padding: 0 !important;
}

.vjs-menu li span {
  color :white;
}

.vjs-menu li.vjs-selected span{
  color:#2B333F;
}



//

.vjs-picture-in-picture-control {
  width: 12rem!important;
}


.vjs-picture-in-picture-control .vjs-icon-placeholder {
  position: absolute!important;
  left: 10px!important;
  top: -7px!important;
}



.vjs-icon-picture-in-picture-exit:before,
.video-js.vjs-picture-in-picture .vjs-picture-in-picture-control:before {
  content: "元に戻す"!important;
} 
.vjs-icon-picture-in-picture-exit:before,
.video-js.vjs-picture-in-picture .vjs-picture-in-picture-control .vjs-icon-placeholder:before{
  font-size: 1.2em;
  margin-top: 7px!important;
}
.vjs-icon-picture-in-picture-enter:before, .video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before{
    font-size: 1.4em;
  margin-top: 5px!important;
}
.vjs-icon-picture-in-picture-enter:before,
.video-js .vjs-picture-in-picture-control:before {
  content: "独立して表示"!important;
  color: #333;
  font-size: 11px;
  padding-left: 28px;
}

.vjs-picture-in-picture-control {
  background-color: #fff!important;
    height: 26px!important;
    margin-top: 8px!important;
    border-radius: 8px!important;
    padding-top:  2px!important;
    &:hover {
  background-color: #ededed!important;

    }
}

.vjs-picture-in-picture-control .vjs-icon-placeholder:before{
  color: #333;
}