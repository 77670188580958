/*--------------------------------------------------------------------------------------------------*/


// 画像上下左右中央（親要素につけると子要素が中央になる
// how : @include flexbox--center();

@mixin flexbox--center{
  display: flex;
  justify-content: center;
  align-items: center;
}







//Flex box(ulにつける)
//http://coliss.com/articles/build-websites/operation/css/css3-flexbox-properties-by-scotch.html

//flexSet(ul)
//how : @include flexSet(wrap, space-between, row, stretch);

@mixin flexbox{
  display:-webkit-box;
  display:-moz-box;
  display:-ms-flexbox;
  display:-webkit-flex;
  display:flex;
}

@mixin flexSet($wrap:wrap, $justify:space-between, $direction:row, $align:stretch) {
  @include flexbox();

  // flex-flex-wrap
  //nowrap,  wrap
  -ms-flex-wrap: $wrap;
  -webkit-flex-wrap: $wrap;
  flex-wrap: $wrap;

  // flex-justify-content(分布)
  // space-between,  space-around,  center,  flex-start,  flex-end
  -ms-flex-pack: $justify;
  -webkit-justify-content: $justify;
  justify-content: $justify;

  // flex-direction(方向)
  // row(縦),  row-reverse(横逆),  column(縦),  column-reverse(縦逆)
  -ms-flex-direction: $direction;
  -webkit-flex-direction: $direction;
  flex-direction: $direction;

  // flex-align-items(縦位置)
  // stretch,  flex-start,  flex-end,  baseline
  -ms-flex-align: $align;
  -webkit-align-items: $align;
  align-items: $align;
}

.flex--sb{
  @include flexSet(wrap, space-between, row, stretch);
  @media screen and (max-width: $break-point-mobile) {
    >li{width: 100%;}
  }
}

.flex--sa{
  @include flexSet(wrap, space-around, row, stretch);
  @media screen and (max-width: $break-point-mobile) {
    >li{width: 100%;}
  }
}
.flex--c{
  @include flexSet(wrap, center, row, stretch);
  @media screen and (max-width: $break-point-mobile) {
    >li{width: 100%;}
  }
}

/*--------------------------------------------------------------------------------------------------*/
// ul_flex(ulにつける)

.ul_flex{
  width:100%;
  display:flex;
  justify-content: center;
  padding:0;
  list-style: none;
  flex-wrap: wrap;

  @media screen and (max-width: $break-point-mobile-min) {
    display: block;
    >li{
      width: 100%!important;
    }
  }
}

.ul_flex--3{
  @extend .ul_flex;
  >li{
    width: 33.3%;
  }
}
.ul_flex--4{
  @extend .ul_flex;
  >li{
    width: 25%;
  }
}
.ul_flex--3-2{
  @extend .ul_flex--3;
  @media screen and (max-width: $break-point-mobile-min) {
    display: block;
    >li{
      width: 50%!important;
      float: left;
    }
  }
}
.ul_flex--4-2{
  @extend .ul_flex--4;
  @media screen and (max-width: $break-point-mobile-min) {
    display: block;
    >li{
      width: 50%!important;
      float: left;
    }
  }
}


.ul_flex--sb{
 display: flex;
 width: 100%;
 //flex-wrap:wrap;
 > li {
   list-style: none;
   flex-grow: 1;
   box-sizing: border-box;
 }
}


//flex
.p-mall {
  // 画像横３つ(sp1つ)リスト
  &-row3-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    &__list {
      flex-basis: calc(100% / 3 - 1.4%) ;
      margin-bottom: 30px;
      margin: 0 1%;
      .is-sp & {
        flex-basis: calc(100% / 1 - 1%) ;
      }
      &:nth-child(3n+1) {
        margin: 0 1% 0 0;
      }
      &:nth-child(3n) {
        margin: 0 0 0 1%;
      }
    }
    &__image {
      width: 100%;
      height: auto;
    }
    &__descr {
      text-align: center;
    }
  }
}