
// 画像上下左右中央
.u-position-center-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}


//absoluteの際に中央から距離をつける
.u-position-from_center {
  position: absolute;
  right: calc(50% - 300px);
}