
/*--------------------------------------------------------------------------------------------------*/
//circle
// how :  @include circle-setting(40px, #fff, 1px, #E35C61, #E35C61);

@mixin circle-setting($w, $bg, $b, $bc, $c) {
  width: $w;
  height: $w;
  display: inline-block;
  border-radius: 50%;
  background-color: $bg;
  border:$b solid $bc;
  color: $c;
  text-align:center;
  line-height: $w;
  vertical-align: middle;
}



/*--------------------------------------------------------------------------------------------------*/
// arrow-making
// how :  @include arrow_normal-setting(right, 30px, 5px, #333);

@mixin arrow_normal-setting($direction:left, $size:30px, $border-size:5px, $color:#333) {
  width: $size;
  height: $size;
  border: $border-size solid;
  content: "";
  
  @if $direction == left {
    border-color:  transparent transparent $color $color;
    transform: rotate(45deg);
  }
  @else if $direction == right {
    border-color: $color $color transparent transparent;
    transform: rotate(45deg);
  }
  @else if $direction == top {
    border-color: $color $color transparent transparent;
    transform: rotate(-45deg);
  }
  @else {
    border-color:  transparent transparent $color $color;
    transform: rotate(-45deg);
  }
}

/*--------------------------------------------------------------------------------------------------*/
// arrow-setting
// how :  @include arrow-setting(front, bottom, 10px, #fff);
//（front/back）

@mixin arrow-setting($positon:front, $direction:left, $size:8px, $arrCol:false) {
  $colArrow: if( $arrCol != false, $arrCol, $colFnt);

  $pos: if($positon == front, left, right);
  $bdPosA: left;
  $bdPosB: right;
  $bdPosC: top;

  @if $direction == left {
    $bdPosA: top;
    $bdPosB: bottom;
    $bdPosC: right;
  }
  @else if $direction == right {
    $bdPosA: top;
    $bdPosB: bottom;
    $bdPosC: left;
  }
  @else if $direction == top {
    $bdPosA: left;
    $bdPosB: right;
    $bdPosC: bottom;
  }
  @else {
    $bdPosA: left;
    $bdPosB: right;
    $bdPosC: top;
  }

  position: relative;

  &:before{
    content: "";
    position: absolute;
    top: 0;
    #{$pos}: 0;
    border-#{$bdPosA}: $size solid transparent;
    border-#{$bdPosB}: $size solid transparent;
    border-#{$bdPosC}: $size*2-2 solid $colArrow;
  }
}


/*--------------------------------------------------------------------------------------------------*/
// make-triangle
// :after{ @include make-triangle(eight, 10px, #007bff); }

@mixin make-triangle($direction, $size, $color){
  content: "";
  width: 0;
  height: 0;
  margin: auto;
  border-style: solid;

 @if $direction == top {
  border-width: 0 $size $size $size;
  border-color: transparent transparent $color transparent;
 }
 @if $direction == right {
  border-width: $size 0 $size $size;
  border-color: transparent transparent transparent $color;
 }
 @if $direction == bottom {
    border-width: $size $size 0 $size;
  border-color: $color transparent transparent transparent;
 }
 @if $direction == left {
      border-width: $size $size $size 0;
  border-color: transparent $color transparent transparent;
 }
}