.l-footer{
    padding: 20px 0;
    background: $color-black;
    position: absolute;
    bottom: -34px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @media screen and (max-width: $break-point-mobile) {
        padding: 20px 0;
    }

    &__copyright {
        @extend .ff--primary-sans-serif;
        color: $color-silver;
        font-size: 10px;
        letter-spacing: 1px;
        text-align: center;
    }
}