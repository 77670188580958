
.c-tab-wrap {
  display: flex;
  flex-wrap: wrap;
}
.c-tab-wrap:after {
  content: '';
  width: 100%;
  height: 3px;
  display: block;
  order: -1;
}
.c-tab-label {
  color: $color-gray;
  white-space: nowrap;
  text-align: center;
  padding: 10px 0;
  order: -1;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
}
.c-tab-label:not(:last-of-type) {
  margin-right: 5px;
}
.c-tab-content {
  width: 100%;
  height: 0;
  overflow: hidden;
  opacity: 0;
  border-top: 1px solid #C8C8C8;
  margin-top: -4px;
}
/* アクティブなタブ */
.c-tab-label.active {
  border-bottom: 4px solid $color-primary1;
  color: $color-black;
}
.c-tab-content {
  height: auto;
  overflow: hidden;
  padding: 30px 0px;
  opacity: 1;
  transition: .5s opacity;
}
.c-tab-inner {
  width: 100%;
}
/* ラジオボタン非表示 */
.c-tab-switch {
  display: none;
}

@media (max-width: 992px) {
  .extra-content {
    display: block;
  }
}
