
.p-video {


	&-player {
		background-image: url("https://d5cejsnl5jt47.cloudfront.net/video-material/img/player-background.png");
		background-position: center top;
		background-size: cover;
		background-repeat: no-repeat;
		overflow: hidden;

		&__wrapper {
			@extend .l-wrapper-h;
			padding-top: 40px;
			padding-bottom: 40px;
			@media screen and (max-width: $break-point-mobile) {
				padding: 10px;
			}
		}

		&__video {

			&--inner {
				width:100%;
				height:0;
				padding-bottom:56.25%;
				position:relative;

				>video, iframe {
					width:100%;
					height:100%;
					position:absolute;
					top:0;
					left:0;
				}
			}
		}

		&__index {
			background-color: #fff;
			border-radius: 4px;
			@media screen and (max-width: $break-point-mobile) {
				margin-top: 10px;
			}
			&--selected {
				background-color: $color-primary2;
				span {
					color: $color-white !important;

				}
			}
			&--title {
				border-bottom: 1px solid rgba(#000,.1);
				padding: 10px 15px;
			}
			&--list {
				overflow: auto;
				height: auto;
				@media screen and (max-width: $break-point-mobile) {

				}
				>li {
					border-bottom: 1px solid rgba(#000,.1);
				}
			}
			&__conts {
				@include flexSet(wrap, space-between, row, center);
				padding: 10px 15px;
				cursor: pointer;
				&:hover {
					background-color: $color-primary3;
				}
				&--inner {
					@include flexSet(wrap, flex-start, row, flex-start);
					width: 100%;
					>img {
						margin-top: 5px;
						@media screen and (max-width: $break-point-mobile) {
							margin-top: 0px;
						}
					}
					>span {
						width: 88%;
						@media screen and (max-width: $break-point-mobile) {
							width: 100%;
							max-width: 180px;
						}
					}
				}
				&--title {
					font-size: 12px;
					max-width: 160px;
				}
				&--time {
					font-size: 10px;
					color: $color-gray;
				}
			}
		}
	}



	&-below {
		&__wrapper {
			@include flexSet(wrap, space-between, row, flex-start);

		}
		&__titleUnit {
			margin-bottom: 20px
		}
		&__detailUnit {
			margin-bottom: 50px;
			&--txt {
				margin-bottom: 30px;
				>p {
					@include fonts($s);
				}
			}
			&--list {
				>li {
					>a {
						color: #1976d2;
						text-decoration: none;
						margin-left: 5px;
						&:hover {
							text-decoration: underline;
						}
					}

					&:not(:last-child) {
						margin-bottom: 12px;
					}
				}
			}
		}

		&__linkUnit {
			&__wrapper {
				@include flexSet(wrap, space-between, row, center);
				margin-top: 10px;
			}
			&__manual {
				background-color: #fff;
				border-radius: 12px;
				border: #EAEAEA;
				padding: 24px 32px;
				@media screen and (max-width: $break-point-mobile) {
					margin-bottom: 20px;
				}
			}
			&__support {
				@include heights(190px, auto);
				background-color: #fff;
				border-radius: 12px;
				border: #EAEAEA;
				text-align: center;
				padding: 20px;

				>p {
					@include fonts($xxs);
				}

			}
		}
		&__detail {


		}

		&__history {

		}

	}



	&-footer {
		background-color: #EDEDED;
		padding-bottom: 60px;
		.p-video-player__index__conts--title{
			max-width: 100%!important;
			@include fonts($xxs);
		}
	}

}

.MuiBox-root {
	border-radius: 4px;
	img {
		&:hover {
			opacity: 0.8;
		}
	}
}

@media (max-width: 992px) {
	.side-panel {
		display: none;
	}
}