
%btn-init{
  display: inline-block;
  background: none;
  position: relative;
  z-index: 5;
  box-sizing: border-box;
  text-decoration: none;
  text-shadow: none;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  border: none;
  border-radius: 0px;
  margin: auto;
  padding:0;
  line-height: 1;
  cursor: pointer;
  @include rem-px(font-size,13);
  @include rem-px(letter-spacing,0.8);
}

button {
  cursor: pointer;
  @extend %btn-init;
  @extend %move;
  &:disabled, &.disabled {
    color:#fff;
    filter: none;
    background: $color-silver;

    &:hover {
      background: $color-silver;
      color:#fff;
      @extend %move;
    }
    &:active {
      background: $color-silver;
    }
  }
}
button:active,button:focus {
  outline: none;
}



@mixin button-shape($w, $r, $f) {
  @extend %btn-init;
  
  width: $w;
  padding: $w / 12 0px;
  border-radius: $r;

  font-size: $f;
  @if $w > 300 {
    @media screen and (max-width: $break-point-mobile) {
      padding: $w / 14 0px;
      width: 90%;
    }
  }
}
.c-btn__fr--xsmall {
  @include button-shape(140px, 130px/7, 11px);
}
.c-btn__fr--small {
  @include button-shape(150px, 150px/7, 16px);
}
.c-btn__fr--medium {
  @include button-shape(300px, 300px/9, 20px);
  font-weight: bold;
  &::after { // arrow
    @include position-absolute(28px, 40px, auto, auto);
    @include p-absolute(middle);
    @include arrow_normal-setting(right, 13px, 2px, #fff);
  }
}
.c-btn__fr--large {
  @include button-shape(400px, 400px/9, 24px);
  font-weight: bold;
  &::after { // arrow
    @include position-absolute(38px, 40px, auto, auto);
    @include p-absolute(middle);
    @include arrow_normal-setting(right, 15px, 3px, #fff);
  }
}
.c-btn__f--xsmall {
  @include button-shape(150px, 0, 12px);
}
.c-btn__f--small {
  @include button-shape(140px, 0, 12px);
}
.c-btn__f--medium {
  @include button-shape(300px, 0, 12px);
}
.c-btn__f--large {
  @include button-shape(400px, 0, 24px);
  font-weight: bold;
}


.c-btn {
  &--black {
    background-color: $color-black;
    color: #fff;
    &:hover {
      background-color: lighten($color-black, 4%);
    }
  }
  &--blue {
    background-color: $color-primary1;
    color: #fff;
    &:hover {
      background-color: lighten($color-primary1, 4%);
    }
  }
  &--st--blue {
    background-color: #fff;
    border: 1px solid $color-primary1; 
    color: $color-primary1;
    &:hover {
      background-color: $color-primary1;
      color: #fff;
    }
  }
  &--white {
    background-color: #fff;
    color: #333;
  }
  &--st--white {
    background-color: transparent;
    border: 1px solid #fff; 
    color: #fff;
    i {
      color: #fff;
      margin-right: 8px;
    }
    &:hover {
      background-color: #fff;
      color: #333;
      i {
        color: #333;
      }
      &::after { // arrow
      border-color: $color-primary1 $color-primary1 transparent transparent;
      }
    }
  }
  &--grad {
    background: rgb(99,243,234);
    background: linear-gradient(125deg, rgba($color-primary2,1) 0%, rgba($color-primary1,1) 85%);
    color: #fff;
    &:hover {
      background: rgb(99,243,234);
      background: linear-gradient(125deg, lighten($color-primary2, 4%) 0%, lighten($color-primary1, 4%) 85%);
    }
  }
}
