
//---------------------------------------------------------------------------------------------
// AOS
//---------------------------------------------------------------------------------------------

/*
<div class="aos--mask_img-wrap">
  <div class="aos--mask_img" data-aos="slide-right" data-aos-delay="50" data-aos-duration="300">
      <img src="img01.jpg" class="fitsp" alt="" />
      <span data-aos="slide-right" data-aos-delay="500" data-aos-duration="300"></span>
  </div>
</div>
*/

.aos--mask_img-wrap{
  overflow: hidden;

  .aos--mask_img{
    overflow: hidden;
    position: relative;
    @media screen and (max-width: $break-point-mobile) {
      width: 100%!important;
    }
    span{
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      background: $color-primary1
    }
  }
}

//---------------------------------------------------------------------------------------------


//@extend %fade
%fade {
  transition: 0.1s;
  //+transition-timi ng-function(ease-out)
  &:hover {
    opacity: 0.7;
  }
}

%fade-slow {
  transition: 0.4s;
  //+transition-timi ng-function(ease-out)
}

%fadeIn {
  transition: 0.3s;
  opacity: 0.5;
  //+transition-timi ng-function(ease-out)
  &:hover {
    opacity: 1;
  }
}


//---------------------------------------------------------------------------------------------


%move {
  transition: 0.1s;
  //+transition-timi ng-function(ease-out)
}
%move--2 {
  transition: 0.2s;
  //+transition-timi ng-function(ease-out)
}
%move--3 {
  transition: 0.3s;
  //+transition-timi ng-function(ease-out)
}


//---------------------------------------------------------------------------------------------


%whiteBg {
  transition: 0.1s;
  background: $color-white;
  box-shadow:rgba(112, 112, 112, 0.258824) 0px 0px 4px 2px;
  -webkit-box-shadow:rgba(112, 112, 112, 0.258824) 0px 0px 4px 2px;
  -moz-box-shadow:rgba(112, 112, 112, 0.258824) 0px 0px 4px 2px;
}


//---------------------------------------------------------------------------------------------


@mixin blur-setting($px) {
  -webkit-filter: blur($px);
  -ms-filter: blur($px);
  filter: blur($px);
}

.blur--s{
  @include blur-setting(4px);
}
.blur--m{
  @include blur-setting(10px);
}
.blur--l{
  @include blur-setting(20px);
}



.blur {
  background: inherit;
}

.blur:before {
  content: '';
  position: absolute;
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0;
  background: inherit;
  -webkit-filter: blur(20px) saturate(2);
  -moz-filter: blur(20px) saturate(2);
  filter: blur(20px) saturate(2);
  //filter: url(../img/filters.xml#blur_saturate);
}

//---------------------------------------------------------------------------------------------


@mixin animation-setting($type, $num1, $num2) {
  -moz-animation: $type $num1 $num2 ease both;
  -webkit-animation: $type $num1 $num2 ease both;
  -o-animation: $type $num1 $num2 ease both;
  -ms-animation: $type $num1 $num2 ease both;
  -khtml-animation: $type $num1 $num2 ease both;
  animation: $type $num1 $num2 ease both;
}

.fadeIn-eff{
  @include animation-setting(fadeIn, 0.4s, 0.1s);
}
.fadeInDown-eff{
  @include animation-setting(fadeInDown, 0.3s, 0.1s);
}
.btn_baloon-eff { 
  @include animation-setting(bounceIn, 1s, 0.7s);
}
.header_dropdown-eff { 
  @include animation-setting(fadeInDown, 0.2s, 0s);
}
.header_dropdown_large-eff { 
  @include animation-setting(fadeIn, 0.2s, 0s);
}
.indicator_in-eff { 
  @include animation-setting(fadeIn, 0.4s, 0.1s);
}
.alert-eff{
  @include animation-setting(fadeInDown, 0.5s, 0.1s);
}
