@charset "UTF-8";

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}
dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, iframe,
fieldset, legend, blockquote, input, textarea, p, pre {
  text-align:left;
}
body {
  line-height:1;
}
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
  display:block;
}
nav ul {list-style:none;}
ol, li {list-style:none;}
blockquote, q {
  quotes:none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}
a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  text-decoration: none;
}
ins {
  background:#ff9;
  color:#000;
  text-decoration:none;
}
mark {
  background:#ff9;
  color:#000;
  font-style:italic;
  font-weight:bold;
}
del {text-decoration: line-through;}
abbr[title], dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}
table {
  border-collapse:collapse;
  border-spacing:0;
  font-size:inherit;
  font:100%;
}
th, td {
  font-style:normal;
  font-weight:normal;
  font-size:inherit;
  empty-cells:show;
}
hr {
  display:block;
  height:1px;
  border:0;
  border-top:1px solid #cccccc;
  margin:1em 0;
  padding:0;
}
input, select {
  vertical-align:middle;
}
img {
  /*vertical-align:bottom;*/
  font-size:0;
  line-height: 0;
  vertical-align:bottom;
}
pre {
  white-space: -moz-pre-wrap; /* Mozilla */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap;  /* CSS3 */
  word-wrap: break-word;  /* IE 5.5+ */
}
address {
  font-style:normal;
  font-weight:normal;
}
input, textarea, select {
  font-family:inherit;
  font-size:inherit;
  font-weight:inherit;
  border:0;
}
input, select {
  vertical-align:middle;
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
%clearfix{
  zoom:1;
  &:after{
    display: block;
    content: "";
    clear: both;
  }
}
article, section, div{
  position: relative;
}
/* --------------------------------------------------------------------------------
※Firefoxのスクロールバーの有無でセンタリング位置がズレるのを防ぐ
-------------------------------------------------------------------------------- */
html {
  overflow:scroll;
  overflow:-moz-scrollbars-vertical;
  overflow-x:auto;
}

$break-point-mobile: 992px;
$break-point-mobile-min: 520px;
