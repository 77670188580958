@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);



//primary font
@import url('https://fonts.googleapis.com/css2?family=Archivo&display=swap');
.ff--primary-sans-serif {
  font-family: 'Archivo', sans-serif;
}
@import url(https://fonts.googleapis.com/css?family=GFS+Didot);
.ff--primary-serif {
  font-family: 'GFS Didot', serif;
}



//jp font
%ff--sans-serif{
  font-family:'Noto Sans Japanese', 'ヒラギノ角ゴシック', 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}
%ff--serif {
  font-family: 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'HG明朝E', 'ＭＳ Ｐ明朝', 'MS PMincho', 'MS 明朝', serif;
}

//-------------------------------------------------------------------------------------------------------
//init

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i, a,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, a, span {

  @extend %ff--sans-serif;
  font-famly:sans-seif;
  font-size: 14px;
  line-height: 1.4;
  color: $color-black;
}



