.l-header {
	background-color: #fff;

	&__wrapper {
		@extend .l-wrapper-h;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	&--title {
		@include fonts($m);//18px
		font-weight: bold;
	}
}
