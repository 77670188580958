

@mixin label-shape($w, $r, $f) {
  
  width: $w;
  padding: $w / 12 0px;
  border-radius: $r;

  font-size: $f;
  @if $w > 300 {
    @media screen and (max-width: $break-point-mobile) {
      padding: $w / 14 0px;
      width: 90%;
    }
  }
}
.c-label--small {
  @include label-shape(140px, 130px/7, 11px);
}
.c-label--medium {
  @include button-shape(100px, 100px/9, 12px);
  font-weight: bold;
}
.c-label--large {
  @include button-shape(400px, 400px/9, 24px);
  font-weight: bold;
}



.c-label {
  &--blue {
    background-color: $color-blue;
    color: #fff;
  }
  &--yellow {
    background-color: $color-yellow;
    color: #fff;
  }
  &--purple {
    background-color: $color-purple;
    color: #fff;
  }
}