/*--------------------------------------------------------------------------------------------------*/
// font
// how @include fonts($xxs);


//font size list
$xxs  : 12px;
$xs   : 14px;
$s    : 16px;
$m    : 18px;
$l    : 24px;
$xl   : 28px;
$xxl  : 36px;
$xxxl : 50px;

@mixin fonts($value) {
  font-size: $value;
  line-height: $value * 1.7;
  letter-spacing: $value * 0.02;
  >span {
   font-size: $value;
   line-height: $value * 1.7;
   letter-spacing: $value * 0.02; 
  }
  @media screen and (max-width: $break-point-mobile) {
    font-size:$value * 0.8;
    line-height: $value * 1.1;
    letter-spacing: $value * 0.02;
    >span {
      font-size:$value * 0.8;
      line-height: $value * 1.1;
      letter-spacing: $value * 0.02;
    }
  }
}


.c-fs--xxs{
  @include fonts($xxs);//12px
}

.c-fs--xs{
  @include fonts($xs);//14px
}

.c-fs--s{
  @include fonts($s);//16px
}

.c-fs--m{
  @include fonts($m);//18px
}

.c-fs--l{
  @include fonts($l);//24px
}

.c-fs--xl{
  @include fonts($xl);//28px
}

.c-fs--xxl{
  @include fonts($xxl);//36px
}

.c-fs--xxxl{
  @include fonts($xxxl);//50px
}



/*--------------------------------------------------------------------------------------------------*/
//rem pix

@mixin rem-px($property, $values) { // @include rem-px(padding, 0 10);
  $px : ();
  $rem: ();
  @each $value in $values {
    @if $value == 0 or $value == auto {
      $px : append($px , $value);
      $rem: append($rem, $value);
    }
    @else {
      $px : append($px,  $value + px);
      $rem: append($rem, ($value / 10 + rem));
    }
  }
  @if $px == $rem {
    #{$property}: $px;
  }
  @else {
    #{$property}: $px;
    #{$property}: $rem;
  }
}

