
$color-pure-black: #000;
$color-dark-pure-black:#242424;
$color-black: #333;
$color-dark: #666;
$color-gray: #999;
$color-silver: #CCC;
$color-light-silver: #D7D7D7;
$color-pale-white: #fafafa;
$color-white: #FFF;

$color-blue: #2CC0FF;
$color-green:#42bc42;
$color-red: #e25b60;
$color-orange: #ffa800;
$color-yellow: #FFD603;
$color-brown: #322B2B;
$color-purple: #843799;

//----------------------------------------------------------------------

$color-link: $color-blue;
$color-background:#F5F5F5;
//----------------------------------------------------------------------



$color-primary1:#18A0FB;
$color-primary2:#426FA5;
$color-primary3:#B1E0FF;



//----------------------------------------------------------------------

