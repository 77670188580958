
.u-d--flex {
  display: flex !important;
  &.is-center {
    align-items: center;
    justify-content: center;
  }
  &.is-between {
    justify-content: space-between;
  }
}

.u-d-block {
  display: block;
}

.u-d--inline-block {
  display: inline-block;
}

