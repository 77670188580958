/*--------------------------------------------------------------------------------------------------*/
// position-absolute
// how :  @include position-absolute(10px, 10px, auto, auto);

@mixin position-absolute($t:0px, $r:0px, $b:0px, $l:0px) {
  position: absolute!important;
  left:$l;
  right:$r;
  top:$t;
  bottom: $b;
}


/*--------------------------------------------------------------------------------------------------*/
// posion absolute で左右(center)、上下(middle)中央
//how : @include p-absolute(center); 
//how : @include p-absolute(middle); 

@mixin p-absolute($type) {
  position: absolute!important;
  margin: auto;

  @if $type == 'center' {
    left: 0;
    right: 0;
  }
  @else if $type == 'middle' {
    top: 0;
    bottom: 0;
  }
}

