

.c-thumbnail-list {
	>li {
		@media screen and (max-width: $break-point-mobile) {
     	 margin-bottom: 20px;
    	}
    	img {
    		border-radius: 12px;
    	}
    	a:hover {
    		opacity: 0.8;
    	}
	}
}
.c-thumbnail-command-list {
	>li {
     	 margin-bottom: 20px;
     	 img {
     	 	border-radius: 12px;
     	 }
     	 a:hover {
     	 	opacity: 0.8;
     	 }
	}
}

.c-vertical-list {
	>li {
		margin-bottom: 12px;
		>a {
			@include flexSet(wrap, space-between, row, flex-start);
		}

	}
	&--img {
		    flex-basis: 40%;
		    >img {
		    	border-radius: 8px;
		    }
		    @media screen and (max-width: $break-point-mobile) {
     			flex-basis: 100%;
    		}
	}
	&--text {
    		flex-basis: 55%;
    		font-size: 13px;
    		@media screen and (max-width: $break-point-mobile) {
     			flex-basis: 100%;
     			display: none;
    		}
	}
}