
.u-hidden {
  display: none !important;
}

.u-hidden--pc{
  display: none!important;
  @media screen and (max-width: $break-point-mobile) {
    display: block!important;
  }
}
.u-hidden--pc--il{
  display: none!important;
  @media screen and (max-width: $break-point-mobile) {
    display: inline-block!important;
  }
}
.u-hidden--sp{
  display: block!important;
  @media screen and (max-width: $break-point-mobile) {
    display: none!important;
  }
}