.u-w100p {
  width: 100% !important;
}

.u-w50p {
  width: 50% !important;
}

.u-w25p {
  width: 25% !important;
}

.u-fit{width: 100%}
.u-fit img{width: 100%}

@media screen and (max-width: $break-point-mobile-min) {
  .u-fitsp{width: 100%!important}
  .u-fitsp img{width: 100%!important}
}
