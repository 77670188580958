@charset "UTF-8";

html,body {
  font-size: 62.5%;
  overflow: auto;
  background-color: $color-background;
}

article, section, div, header, form, footer{
  position: relative;
}

p, a, li, span{
}
a {
  transition: color 0.3s;
  &:hover {

  }
  img:hover {

  }
}
h2 {
  @include fonts($l);//24px
}
h3 {
  @include fonts($m);//18px
}
h4 {
  @include fonts($s);//16px
}

main {
  overflow: hidden;
}

#container{
}
.root{}
.lower{
  background-color: #fff;
}


//----------------------------------------------------------------------------------------------------------------------------------------------------------

$contents_width: 1200px;
$contents_width--small: 800px;


.l-wrapper-v{
  padding-top: 40px;
  padding-bottom: 40px;
  @media screen and (max-width: $break-point-mobile) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.l-wrapper-v--small{
  padding-top: 35px;
  padding-bottom: 35px;
  @media screen and (max-width: $break-point-mobile) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.l-wrapper-h{
  max-width: $contents_width;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;
  @media screen and (max-width: $break-point-mobile) {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.l-wrapper-h--small{
  max-width: $contents_width--small;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;
  @media screen and (max-width: $break-point-mobile) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.l-wrapper{
  @extend .l-wrapper-v;
  @extend .l-wrapper-h;
}

.l-wrapper--small{
  @extend .l-wrapper-v--small;
  @extend .l-wrapper-h;
}

.l-wrapper-left{
  width: 70%;
  padding-right: 50px;
  @media screen and (max-width: $break-point-mobile) {
    padding-right: 0px;
  }
}
.l-wrapper-right{
  width: 30%;
}


%e-box {
  background-color: #fff;
  border-radius: 12px
}

%e-box-shadow {
  @include box-shadow(0px, 2px, 10px, 0px, rgba(#000,0.1));
}

.learnRobo {
  min-height: 100vh;
  overflow: hidden;
}