// 便利なマージン集

.u-m0 {
  margin: 0 !important;
}

.u-mt0 {
  margin-top: 0 !important;
}

.u-mt4p {
  margin-top: 4px !important;
}

.u-mt8p {
  margin-top: 8px !important;
}

.u-mt12p {
  margin-top: 12px !important;
}

.u-mt16p {
  margin-top: 16px !important;
}

.u-mt20p {
  margin-top: 20px !important;
}

.u-mt24p {
  margin-top: 24px !important;
}

.u-mt32p {
  margin-top: 32px !important;
}

.u-mt36p {
  margin-top: 36px !important;
}

.u-mt40p {
  margin-top: 40px !important;
}

.u-mt48p {
  margin-top: 48px !important;
}

.u-mt64p {
  margin-top: 64px !important;
}

.u-mb0 {
  margin-bottom: 0 !important;
}

.u-mb4p {
  margin-bottom: 4px !important;
}

.u-mb6p {
  margin-bottom: 6px !important;
}

.u-mb8p {
  margin-bottom: 8px !important;
}

.u-mb12p {
  margin-bottom: 12px !important;
}

.u-mb14p {
  margin-bottom: 14px !important;
}

.u-mb16p {
  margin-bottom: 16px !important;
}

.u-mb20p {
  margin-bottom: 20px !important;
}
.u-mb20p-sp {
  margin-bottom: 20px !important;
}
.u-mb24p {
  margin-bottom: 24px !important;
}

.u-mb32p {
  margin-bottom: 32px !important;
}

.u-mb40p {
  margin-bottom: 40px !important;
}

.u-mb56p {
  margin-bottom: 56px !important;
}

.u-mb60p {
  margin-bottom: 60px !important;
}

.u-mb72p {
  margin-bottom: 72px !important;
}

.u-ml4p {
  margin-left: 4px !important;
}

.u-ml8p {
  margin-left: 8px !important;
}

.u-ml16p {
  margin-left: 16px !important;
}

.u-ml20p {
  margin-left: 20px !important;
}

.u-ml28p {
  margin-left: 28px !important;
}

.u-ml32p {
  margin-left: 32px !important;
}

.u-mr4p {
  margin-right: 4px !important;
}

.u-mr8p {
  margin-right: 8px !important;
}

.u-mr16p {
  margin-right: 16px !important;
}

.u-mr24p {
  margin-right: 24px !important;
}

.u-mr32p {
  margin-right: 32px !important;
}




// SP

.u-m0 {
  @media screen and (max-width: $break-point-mobile-min) {
    margin: 0 !important;
  }
}

.u-mt0 {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-top: 0 !important;
  }
}

.u-mt4p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-top: 4px !important;
  }
}

.u-mt8p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-top: 8px !important;
  }
}

.u-mt12p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-top: 12px !important;
  }
}

.u-mt16p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-top: 16px !important;
  }
}

.u-mt20p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-top: 20px !important;
  }
}

.u-mt24p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-top: 24px !important;
  }
}

.u-mt32p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-top: 32px !important;
  }
}

.u-mt36p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-top: 36px !important;
  }
}

.u-mt40p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-top: 40px !important;
  }
}

.u-mt48p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-top: 48px !important;
  }
}

.u-mt64p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-top: 64px !important;
  }
}

.u-mb0 {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 0 !important;
  }
}

.u-mb4p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 4px !important;
  }
}

.u-mb6p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 6px !important;
  }
}

.u-mb8p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 8px !important;
  }
}

.u-mb12p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 12px !important;
  }
}

.u-mb14p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 14px !important;
  }
}

.u-mb16p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 16px !important;
  }
}

.u-mb20p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 20px !important;
  }
}
.u-mb20p-sp-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 20px !important;
  }
}
.u-mb24p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 24px !important;
  }
}

.u-mb32p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 32px !important;
  }
}

.u-mb40p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 40px !important;
  }
}

.u-mb56p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 56px !important;
  }
}

.u-mb60p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 60px !important;
  }
}

.u-mb72p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-bottom: 72px !important;
  }
}

.u-ml4p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-left: 4px !important;
  }
}

.u-ml8p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-left: 8px !important;
  }
}

.u-ml16p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-left: 16px !important;
  }
}

.u-ml20p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-left: 20px !important;
  }
}

.u-ml28p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-left: 28px !important;
  }
}

.u-ml32p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-left: 32px !important;
  }
}

.u-mr4p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-right: 4px !important;
  }
}

.u-mr8p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-right: 8px !important;
  }
}

.u-mr16p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-right: 16px !important;
  }
}

.u-mr24p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-right: 24px !important;
  }
}

.u-mr32p-sp {
  @media screen and (max-width: $break-point-mobile-min) {
    margin-right: 32px !important;
  }
}

.u-pt0 {
  padding-top: 0 !important;
}
