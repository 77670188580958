

.c-contact_form {

	//init
	&--input {
		input, textarea, select{
			background-color: #e9e9e9;
			padding: 20px;
			color: #333;
			border-radius: 4px;
			width: 100%;
			border: 1px rgba(#f5f5f5,0.1) solid;
		}
		input::-webkit-input-placeholder {
			color: $color-silver;
		}
		input:-ms-input-placeholder {
			color: $color-silver;
		}
		input::-moz-placeholder {
			color: $color-silver;
		}
		input:focus, textarea:focus {
			background-color: $color-white;
			border: 1px #269fdb solid;
			outline: 0;
		}

		input, textarea {
			property: value;
			background-color: #e9e9e9;
		}
		input:focus, textarea:focus {
			background-color: #f2f2f2;
		}
		label {
			font-size: 16px;
			font-weight: bold;
		}

	}



	&--label {
		font-weight: normal;
		font-size: 12px;
		border: 1px solid #cc001e;
		border-radius: 4px;
		color: #cc001e;
		padding: 3px 8px;
		margin-left: 10px;
	}
	&__dl {
		>dt {
			margin-bottom: 10px;
		}
		>dd {
			margin-bottom: 30px;
		}
	}


	//submit btn
	&__submit {
		text-align:center;
		margin-top: 30px;
	}
}
