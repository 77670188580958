
/*--------------------------------------------------------------------------------------------------*/
//@include widths(980px, 100%);

@mixin widths($width, $width-sp) {
  width: $width;
  @media screen and (max-width: $break-point-mobile) {
    width: $width-sp;
  }
}


/*--------------------------------------------------------------------------------------------------*/
//@include heights(500px, 300px);

@mixin heights($height, $height-sp) {
  height: $height;
  @media screen and (max-width: $break-point-mobile) {
    height: $height-sp;
  }
}

