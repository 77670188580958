
.p-home {

	.p-home-head {

		background-color: #F8F9F9;
	}
	.p-video-player__index--list {
		height: auto;
		@media screen and (max-width: $break-point-mobile) {
			height: 100px;
		}
	}
	.p-video-player__index__conts--title{
		max-width: 100%!important;
		@include fonts($xxs);
	}
}