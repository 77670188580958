

//--- text align

.u-ta--center {
  text-align: center !important;

  &__text {
    display: inline-block !important;
    text-align: left !important;
  }
}

.u-ta--center--sp {
  @media screen and (max-width: $break-point-mobile-min) {
    text-align: center !important;
  }
}


//--- text color

.u-tc--pure-black { color:$color-pure-black!important;}
.u-tc--black { color:$color-black!important; }
.u-tc--dark { color:$color-dark!important; }
.u-tc--gray { color:$color-gray!important; }
.u-tc--silver { color:$color-silver!important; }
.u-tc--white { color:$color-white!important; }
.u-tc--red{ color:$color-red!important;}


//- text link

.u-tlink--blue {
  color: $color-link;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}


//- display

.u-display1 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .u-display1 {
    font-size: 5.6rem;
  }
}

@media (min-width: 992px) {
  .u-display1 {
    font-size: 8rem;
  }
}

.u-display2 {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.1rem;
}

@media (min-width: 768px) {
  .u-display2 {
    font-size: 4.6rem;
    line-height: 1.742;
  }
}

@media (min-width: 992px) {
  .u-display2 {
    font-size: 6.2rem;
  }
}

.u-display3 {
  font-size: 4rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .u-display3 {
    font-size: 5.8rem;
  }
}

.u-display4 {
  font-size: 2.8rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .u-display4 {
    font-size: 3.2rem;
  }
}

@media (min-width: 992px) {
  .u-display4 {
    font-size: 4.6rem;
  }
}

.u-display5 {
  font-size: 1.3rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .u-display5 {
    font-size: 2.2rem;
  }
}



//- headline

.u-headline1 {
  font-size: 3.2rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .u-headline1 {
    font-size: 4rem;
  }
}

.u-headline2 {
  font-size: 2.4rem;
  font-weight: 700;
}

.u-headline2 span {
  font-weight: 200;
}

@media (min-width: 768px) {
  .u-headline2 {
    font-size: 3.2rem;
  }
}

@media (min-width: 992px) {
  .u-headline2 {
    font-size: 3.8rem;
  }
}

.u-headline3 {
  font-size: 2rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .u-headline3 {
    font-size: 2.4rem;
  }
}

@media (min-width: 992px) {
  .u-headline3 {
    font-size: 2.4rem;
  }
}

.u-headline4 {
  font-size: 1.8rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .u-headline4 {
    font-size: 1.8rem;
  }
}

.u-headline5 {
  font-size: 1.6rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .u-headline5 {
    font-size: 1.6rem;
  }
}
